@import "materialize-src/sass/materialize";
html, body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  /* テキスト選択禁止 */
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

body {
  /* background-color: #9d0420; カスタマイズ対象: 画面背景色*/
  /* background: #101e38 url(../images/bg.jpg) no-repeat top center; */
  /* background-attachment: fixed; */
  /* background-size: cover; */
  &:after {
      position: fixed;
      top: 0; left: 0;
      width: 100%; height: 100%;
      content: "";
      background: #e0eae1;
      background-size: cover;
      z-index: -1;
  }
}

.material-icons.md-36 { font-size: 36px; }
.material-icons.md-80 { font-size: 80px; }
.material-icons.md-110 { font-size: 110px; }

h5 {
  color: #014f27; /*カスタマイズ対象: 文字色*/
  margin-bottom: 20px;
}

h5.place {
  margin-top: 40px;
}

h5#prize-title {
  padding: 0 20px;
}

p {
  color: #014f27; /*カスタマイズ対象: 文字色*/
}

img.result-img {
  padding-top: 20%;
  width: 45%;
}

img.banner-img {
  width: 70%;
}

/* loading */
div#loading-page {
  width: 100%;
  height: 100%;
  .valign-wrapper {
    width: 100%;
    height: 100%;
    .center-align {
      width: 100%;
    }
  }
}

/* sending */
div#sending-page {
  width: 100%;
  height: 100%;

  /* main-visual */
  div#sending-visual-wrapper {
    margin-top: 10%;
  }

  div#sending-visual-wrapper img {
    width: 100%;
    max-width: 700px;
    padding: 20px 20px 0px 20px;
  }

  .valign-wrapper {
    width: 100%;
    height: 100%;
    .center-align {
      width: 100%;
    }
  }
}

/* wait page */
div#wait-page {
  /* main-visual */
  div#main-visual-wrapper img {
    width: 100%;
    padding: 20px 10px 0px 10px;
  }

  .top-logo {
    padding: 20px 0px 0px 0px;
  }
  h5 {
    padding: 0px 20px;
  }
  p {
    padding: 0px 20px;
  }
}

/* start page */

div#start-page {
  position: absolute;
  width: 100%;

  /* main-visual */
  div#main-visual-wrapper {
    margin-top: 0%;
  }

  div#main-visual-wrapper img {
    width: 80%;
    max-width: 700px;
    padding: 20px 10px 0px 10px;
  }

  /* sub-visual */
  div#sub-visual-wrapper {
    margin-top: 0%;
  }

  div#sub-visual-wrapper img {
    width: 40%;
    max-width: 700px;
    padding: 20px 10px 0px 10px;
  }

  .top-logo {
    padding: 20px 10px 0px 10px;
  }

  div#top-image-wrapper img {
    width: 80%;
    max-width: 700px;
    padding: 20px 10px 0px 10px;
  }

  h5 {
    font-size: xx-large;
    padding: 0px 20px;
  }
  p {
    padding: 0px 40px;
  }
  p.notice {
    font-weight: bold;
  }
  div#start-btn-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .coupon-img {
    width: 60%;
  }
  .coupon-desc {
    margin: 0px;
  }
  p.privacy {
    padding-bottom: 50px;
  }
  div.prize-wrapper {
    display: flex;
    justify-content: center;
  }
  ul {
    padding: 0px 30px 0px 30px;
  }
  ul li {
    list-style-type: none;
    text-indent: -1em;
    color: #014f27;
    font-size: medium;
  }
  ul li.prize-item {
    display: flex;
    justify-content: space-between;
    width: 330px;
    font-size: small;
    div.align-right {
      text-align: right;
    }
  }
  ul.asterisk li:before {
    display: inline;
    content: "・";
  }
}

/* auth start page */
div#auth-start-page {
  position: absolute;
  width: 100%;

  h5 {
    font-size: xx-large;
    padding: 30px 20px;
  }
  p {
    padding: 0px 20px;
  }
  p.notice {
    font-weight: bold;
  }
  div#auth-start-btn-wrapper {
    margin-top: 200px;
    margin-bottom: 50px;
  }
}

/* auth sms page */
div#auth-sms-page {
  position: absolute;
  width: 100%;
  padding: 0 50px;

  h5 {
    font-size: xx-large;
    padding: 30px 20px;
  }
  p.notice {
    font-weight: bold;
  }
  p#auth-sms-error {
    color: red;
  }
  div#sms-input-loader {
    margin-top: 50px;
  }
  div#sms-input-field {
    margin-top: 50px;
  }
  div#auth-sms-btn-wrapper {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  input#sms-input {
    font-size: x-large;
  }
  div#coupon-optin-input-field {
    margin: 0px 40px 45px 40px;
  }
  label#coupon-optin-label {
    font-size: normal !important;
  }
}

/* auth code page */
div#auth-code-page {
  position: absolute;
  width: 100%;
  padding: 0 50px;

  h5 {
    font-size: xx-large;
    padding: 30px 20px;
  }
  p {
    padding: 0px 41px;
  }
  p.notice {
    font-weight: bold;
  }
  p#auth-code-error {
    color: red 
  }
  div#auth-code-input-loader {
    margin-top: 80px;
  }
  div#auth-code-input-field {
    margin-top: 80px;
    //margin-left: 50px;
    //margin-right: 50px;
  }
  div#auth-code-btn-wrapper {
    margin-top: 150px;
    margin-bottom: 50px;
  }
  input#auth-code-input {
    font-size: xx-large;
    text-align: center;
  }
}

/* auth ok page */
div#auth-ok-page {
  position: absolute;
  width: 100%;

  h5 {
    font-size: x-large;
    padding: 30px 20px;
  }
  p {
    padding: 0px 20px;
  }
  p.notice {
    font-weight: bold;
  }
  div#auth-ok-btn-wrapper {
    margin-top: 120px;
    margin-bottom: 50px;
  }
}

/* result */
div#result-page {
  p#result-title {
    margin-top: 30px;
    font-size: medium;
  }
  #use-btn-wrapper {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  #used-mask {
    position: absolute;
    width: 100%;
    height: 120%;
    background: rgba(0,0,0, 0.8);
    position: fixed;
    top: 0px;
    left: 0px;
    h5 {
      margin-top: 50px;
      padding: 0px 20px;
    }
  }
}

/* thanks */
div#thanks-page {
  h4 {
    color: white;
    padding: 0px 20px;
  }

  h5 {
    margin-top: 20%;
    padding: 0px 20px;
  }

  p {
    padding: 20px 20px;
  }

  #win-wrapper {
    margin-top: 10%;
  }

  #use-btn-wrapper {
    margin-top: 15%;
    margin-bottom: 200px;
    a {
      color: white;
    }
  }

  #used-mask {
    position: absolute;
    width: 100%;
    height: 120%;
    background: rgba(0,0,0, 0.8);
    position: fixed;
    top: 0px;
    left: 0px;
    h5 {
      color: white;
      margin-top: 10%;
      padding: 0px 20px;
    }
  }

  #credit-div {
      margin-top: 50px;
  }

  /* lose-visual */
  div#lose-visual-wrapper {
    margin-top: 5%;
  }

  div#lose-visual-wrapper img {
    width: 100%;
    max-width: 700px;
    padding: 20px 20px 0px 20px;
  }

  div#used-visual-wrapper {
    margin-top: 5%;
  }

  div#used-visual-wrapper img {
    width: 100%;
    max-width: 700px;
    padding: 20px 20px 0px 20px;
  }
}

/* error */
div#error-page {

  div#error-wrapper {
    margin-top: 10%;
  }

  h5 {
    margin-top: 50px;
    padding: 0px 20px;
  }

  p {
    padding: 0px 20px;
  }
}

/* expired */
div#expired-page {
  /* main-visual */
  div#main-visual-wrapper img {
    width: 100%;
    padding: 20px 10px 0px 10px;
  }

  h5 {
    margin-top: 50px;
    padding: 0px 20px;
  }

  p {
    padding: 0px 20px;
  }
}

/* survey */
div#my-survey {
  height:100%;
  position:relative;
  div.long-page {
    margin-bottom: 100%;
  }
  h5 {
    margin-bottom: 8%;
    font-weight: 300;
    strong {
      font-weight: 600;
    }
  }
  h5.center {
    margin-top: 10%;
  }
  h5.align-left {
    text-align: left;
  }

  p.notice {
    margin-bottom: 10%;
  }

  select {
    color: #6f5825;
    background-color: #014f27; /*カスタマイズ対象: セレクトプルダウン*/
  }

  input {
    font-size: x-large;
  }

  textarea {
    color: black;
    font-size: x-large;
  }

  h5.subtitle {
    margin-bottom:0px;
  }

  div.logo-wrapper {
    padding-top: 50px;
  }

  div.input-with-label-wrapper {
    width:100%;
    text-align:center;
    input {
      font-size: xx-large;
    }
    div.input-field {
      width:25%;
      display:inline-block;
    }
    div#page-8-input-field {
      width:45%;
    }
    p {
      font-size:xx-large;
      display:inline-block;
    }
  }
}

div#answer-error-mask {
  position: absolute;
  width: 100%;
  height: 120%;
  background: rgba(0,0,0, 0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  h5 {
    margin-top: 150px;
  }
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 70px;  /* submitボタン 80px, コントロール 70px */
  width: 100%;
  background: rgba(16,30,57, 0.5); /*カスタマイズ対象: アンケートフッター色*/
  /* 背景色から明度-20*/

  div#submit-btn-wrapper {
    height: 80px;
    .valign-wrapper {
      height: 100%;
      .center-align {
        width: 100%;
      }
    }
    a#submit-btn {
      background-color: #ff1493; /*カスタマイズ対象: 送信ボタン*/
      color: #fff;
      /* 目立つ色を指定すること */
    }
  }

  div#gonext-btn-wrapper {
    height: 80px;
    .valign-wrapper {
      height: 100%;
      .center-align {
        width: 100%;
      }
    }
    a#gonext-btn {
      background-color: #ffffff; /*カスタマイズ対象: 次へボタン*/
      /* 目立つ色を指定すること */
    }
  }

  .progress-wrapper {
    margin:0 10px;
    div#progress-indicator-background {
      background-color: #cfd2d7; /*カスタマイズ対象: プログレス背景*/
      /* 目安は背景色の明度+80*/
    }
    div#progress-indicator {
      width: 0%;
      background-color: #6eb64e; /*カスタマイズ対象: プログレス表示*/
      /* 目安は背景色の明度-60*/
    }
  }

  .footer-control {
    width: 100%;
    .button-wrapper {
      margin-left: 10px;
      margin-right: 10px;
      a#prev-btn {
        /* background-color: #cddc39; /*カスタマイズ対象: 次へボタン*/
        color: #000;
        background-color: #ffffff; /*カスタマイズ対象: 戻るボタン*/
        /* 目安は背景色の明度-60*/
      }
      a#next-btn {
        color: #000;
        background-color: #ffffff; /*カスタマイズ対象: 戻るボタン*/
        /* 目安は背景色の明度-60*/
      }
      p#current-label {
        display:inline;
        color: white;
        padding-right:10px;
      }
      p#remaining-label {
        display:inline;
        color: white;
        padding-left:10px;
      }
    }
  }

}

.page {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-top: 10%;
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 30%;
}

img#question-img {
  padding-top: 40px;
}

img.crown {
  position: absolute;
}

.btn-large {
  width: 30%;
  height: auto;
  min-width:250px;
  min-height: 50px;
  line-height: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom:10px;
  background-color: #6eb64e; /*カスタマイズ対象: アンケートボタン色*/
  color: #fff; /*カスタマイズ対象: アンケートボタン文字色*/
  text-transform: none !important;
  i {
    color: #10305b; /*カスタマイズ対象: アンケートボタン文字色*/
  }
}

.btn-large:hover {
  background-color: #d2e8c7; /*カスタマイズ対象: アンケートボタン選択済色*/
  /* 未選択色の明度を70%アップした色 */
}

a.selected {
  background-color: #d2e8c7 !important; /*カスタマイズ対象: アンケートボタン選択済色と共通*/
  /* 未選択色の明度を70%アップした色 */
}

i.right {
  width: 30px;
  /* margin-left: -30px; */
}

/* テキストフィールド */
.input-field {
  margin: 7% auto 0px auto;
  min-width: 50px;
  max-width: 500px;
}
.input-with-label input{
  width: 70%;
}

.small-input-with-label input,
.small-input-with-label-large input{
  width: 30%;
  text-align: center;
}

.input-with-label span, .small-input-with-label span{
  font-size: x-large;
  color: #000;
}

.small-input-with-label-large span{
  font-size: large;
  color: #000;
}

input{
  font-size: x-large;
  color: #014f27; /*カスタマイズ対象: テキストフィールド色*/
  border-bottom: 1px solid #014f27 !important; /*カスタマイズ対象: テキストフィールド下線*/
}
input[type=text]:focus:not([readonly]),
input[type=number]:focus:not([readonly]) {
  color: #014f27; /*カスタマイズ対象: テキストフィールド色*/
  border-bottom: 1px solid #014f27;
  box-shadow: 0 1px 0 0 #014f27; /*カスタマイズ対象: テキストフィールド下線*/
}
.input-field label,
input[type=text]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label
{
  color: #014f27; /*カスタマイズ対象: テキストフィールドラベル色*/
  font-size: large;
}

textarea.materialize-textarea {
  border-bottom: 1px solid #000;
}

h6.error-msg {
  color: red;
  font-size: large;
  padding: 20px;
}

/* SNSボタン */
a#line-btn {
  background-color: #03c302;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

[type="radio"]:checked + label::after, [type="radio"].with-gap:checked + label::after {
    background-color: white !important;
}
[type="radio"]:checked + label::after, [type="radio"].with-gap:checked + label::before, [type="radio"].with-gap:checked + label::after {
    border: 2px solid white !important;
}

[type="radio"]:not(:checked) + label::after, [type="radio"].with-gap:not(:checked) + label::before, [type="radio"].with-gap:not(:checked) + label::after {
    border: 2px solid white !important;
}

.checkbox-indigo.filled-in[type="checkbox"] + label:after{
    border: 2px solid white;
    background: transparent;
    width: 20px;
    height: 20px;
}
.checkbox-indigo.filled-in[type="checkbox"]:checked + label:after{
    background: #ffcc01;
}
.checkbox-indigo.filled-in[type="checkbox"]:checked + label:before{
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 8px;
    height: 14px;
}

li {
  text-align: left;
  color: white;
  font-size: larger;
}

div.videowrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin: 0 auto;
}
h5 span.caution {
  font-weight: bold;
  text-decoration: underline;
}
.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 768px) {
  #start-radio-wrapper {
    div {
      width: 350px;
    }
    label {
      font-size: x-large;
    }
  }
  div#start-page {
    div#main-visual-wrapper {
      margin-top: 5%;
    }
    p.expire {
      font-weight: bold;
    }
    p.notice {
      padding: 0px 10%;
    }
  }
  .footer {
    height: 90px;
  }
  h5 {
    font-size: x-large;
  }
  p {
    font-size: x-large;
  }
  a.btn-large {
    font-size: x-large;
    width: 50%;
    line-height: 25px;
  }
  div#my-survey {
    h5 {
      font-size: xx-large;
    }
    select {
      font-size: x-large;
    }
  }
  .videowrapper iframe {
      position: absolute;
      margin: auto;
      top: 70%;
      left: 70%;
      transform: translate(-79%, -70%);
      width: 70%;
      height: 70%;
  }
  div#thanks-page #win-wrapper {
    margin-top: 5%;
  }
}

@media screen and (max-width: 320px) {
  div#start-page {
    ul li.prize-item {
      width: 210px;
      font-size: x-small;
    }
  }
}